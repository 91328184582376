import { months } from '@/utilities/Date/constantsMonths'

export function data() {
  return {
    months,
    monthSelection: 'January',

    datesWithoutSuffix: [],

    daysOfMonth: this.getDaysOfMonth(),
    daySelection: this.includeEvery ? 'Any Day' : '1st'
  }
}
