/*
	import { months } from '@/utilities/Date/constantsMonths'
*/

export const months = Object.freeze([
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
])
