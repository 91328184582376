<template>
  <div :style="{ padding: '2in' }">
    <MonthAndDay ref="monthAndDay" />
    <b-button @click="getValues">get values</b-button>
  </div>
</template>

<script>
import MonthAndDay from './../index.vue'

export default {
  components: {
    MonthAndDay
  },
  methods: {
    getValues() {
      console.log(this.$refs.monthAndDay.getValues())
    }
  }
}
</script>
