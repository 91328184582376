import { allDatesInMonth } from '@/utilities/Date/allDatesInMonth'
import moment from 'moment'

export const methods = {
  getDaysOfMonth({ monthNum = null, year = null } = {}) {
    const m = moment()

    let month
    if (typeof monthNum === 'number') {
      month = monthNum
    } else {
      month = 1
    }

    let _year
    if (typeof year === 'number') {
      _year = year
    } else {
      _year = m.year()
    }

    const { dates } = allDatesInMonth({
      year: _year,
      month,
      suffix: true
    })

    const { dates: datesWithoutSuffix } = allDatesInMonth({
      year: _year,
      month,
      suffix: true
    })

    this.datesWithoutSuffix = datesWithoutSuffix

    return [
      ...(this.includeEvery ? ['Any Day'] : []),
      ...dates,
      ...(this.includeLast ? ['Last'] : [])
    ]
  },

  getValueWithoutSuffix() {
    let daySelection = this.daySelection

    if (daySelection !== 'Any Day' && daySelection !== 'Last') {
      daySelection = daySelection.slice(0, -2)

      if (daySelection.length === 1) {
        daySelection = '0' + daySelection
      }
    }

    return daySelection
  },

  getValue() {
    return this.daySelection
  },

  /*
		1 to 12

		setMonth ({
			monthNum: 1, // January
			year: 2487
		})
	*/
  setMonth({ monthNum, year, dayOfMonth = null }) {
    console.log('setMonth', { monthNum, year, dayOfMonth })

    this.daysOfMonth = this.getDaysOfMonth({
      monthNum,
      year
    })

    if (dayOfMonth) {
      this.daySelection = dayOfMonth
    }
  }
}

//
