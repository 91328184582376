<template>
  <div :style="{ display: 'flex', alignItems: 'center' }" day-of-month-field>
    <b-field :style="{ margin: 0 }">
      <b-select v-model="daySelection">
        <option v-for="option in daysOfMonth" :value="option" :key="option">
          {{ option }}
        </option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
/*
	import DayOfMonth from '@/components/inputs/DayOfMonth'

	//
	//	value = "1st" || "last" || "2nd"
	//
	<DayOfMonth :includeLast="true" :value="" />
*/

import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  props: {
    onChange: {
      default() {},
      type: Function
    },
    includeEvery: {
      default: false,
      type: Boolean
    },
    includeLast: {
      default: false,
      type: Boolean
    },
    value: String
  },
  data,
  methods,
  watch: {
    daySelection() {
      this.onChange({
        dayOfMonth: this.daySelection
      })
    },
    value() {
      this.daySelection = this.value
    }
  },
  mounted() {
    this.daySelection = this.value
  }
}
</script>
