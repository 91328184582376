<template>
  <b-field :style="{ margin: 0 }" months-field>
    <b-select v-model="monthSelection">
      <option v-for="option in months" :value="option" :key="option">
        {{ option }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
/*
	import Months from '@/components/inputs/Months'
*/

import { months } from '@/utilities/Date/constantsMonths'

export default {
  data() {
    return {
      months,
      monthSelection: 'January'
    }
  },
  props: {
    onChange: {
      type: Function,
      default() {}
    }
  },
  watch: {
    monthSelection() {
      this.onChange({
        month: this.monthSelection
      })
    }
  },
  methods: {
    getMonth() {
      return this.monthSelection
    },
    setMonth({ month }) {
      this.monthSelection = month
    }
  }
}
</script>
