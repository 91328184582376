<template>
  <div :style="{ display: 'flex' }">
    <Months ref="months" :onChange="onMonthChange" />
    <DayOfMonth ref="dayOfMonth" :includeLast="true" />
  </div>
</template>

<script>
/*
	import MonthAndDay from '@/components/inputs/MonthAndDay'
*/

import Months from '@/components/inputs/Months'
import DayOfMonth from '@/components/inputs/DayOfMonth'
import { addSuffix } from '@/utilities/Date/addSuffix'

import { monthStrs } from '@/utilities/Date/constants'

export default {
  components: {
    Months,
    DayOfMonth
  },
  data() {
    return {}
  },
  props: {
    monthAndDay: String
  },
  watch: {
    monthAndDay() {
      this.parseMonthAndDay()
    }
  },
  methods: {
    onMonthChange({ month }) {
      const monthNum = monthStrs[month]

      this.$refs.dayOfMonth.setMonth({
        monthNum,

        // a leap year,
        // so that february can be 28th or 29th
        year: 3004
      })
    },
    getValues() {
      return {
        month: this.$refs.months.getMonth(),
        dayOfMonth: this.$refs.dayOfMonth.getValueWithoutSuffix(),
        dayOfMonth__: this.$refs.day
      }
    },
    parseMonthAndDay() {
      const [month, dayOfMonth] = this.monthAndDay.split('-')

      const monthNum = monthStrs[month]

      this.$refs.dayOfMonth.setMonth({
        monthNum,

        // a leap year,
        // so that february can be 28th or 29th
        year: 3004,

        dayOfMonth: addSuffix(parseInt(dayOfMonth))
      })

      this.$refs.months.setMonth({
        month
      })
    }
  },
  mounted() {
    this.parseMonthAndDay()
  }
}
</script>
